import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReportPanelDirective } from './report-panel.directive';
import { ClientPortalReportPanelDirective } from './client-portal-report-panel.directive';

@NgModule({
  imports: [
    CommonModule
  ],
  declarations: [
    ReportPanelDirective,
    ClientPortalReportPanelDirective,
  ],
  exports: [
    ReportPanelDirective,
    ClientPortalReportPanelDirective,
  ]
})
export class ReportPanelModule {
}
