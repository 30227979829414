import { Injectable } from '@angular/core';
import { Observable, Subject, Subscriber, Subscription } from 'rxjs';
import { ApiService } from '../base/api.service';
import { ApiEndpointsService } from '../base/api-endpoints.service';
import { ServerResponse } from '../../interfaces/base/server.response';
import { Report } from '../../interfaces/report/report';
import { MenuReportCounts } from '../../interfaces/menu-report-counts';
import { SuggestOrganisation } from '../../interfaces/report/suggest-organisation';

@Injectable()

export class ReportService {

  public sourceUpdated: Subject<boolean> = new Subject<boolean>();
  public reportDeletedId: Subject<number> = new Subject<number>();
  public reportOpenedId: Subject<number> = new Subject<number>();

  constructor(private api: ApiService,
              private apiEndpoints: ApiEndpointsService,
  ) {
  }

  index(pageType: 'open' | 'closed' | 'all' = 'open'): Observable<ServerResponse | boolean> {
    return new Observable<ServerResponse>((o: Subscriber<ServerResponse | boolean>) => {
      const subscription: Subscription = this.api.get(this.apiEndpoints.get((pageType === 'open' ? 'reports.open' : (pageType === 'closed' ? 'reports.closed' : 'reports.all'))))
        .subscribe((response: ServerResponse): void => {
          if (typeof response.data !== 'undefined') {
            o.next(<ServerResponse>response);
          } else {
            o.next(false);
          }
        }, (): void => {
          o.next(false);
        });

      return (): void => {
        subscription.unsubscribe();
      };
    });
  }

  missing(type: 'open' | 'closed' | 'all' = 'open', start: number = 0): Observable<ServerResponse | boolean> {
    return new Observable<ServerResponse>((o: Subscriber<ServerResponse | boolean>) => {
      const subscription: Subscription = this.api.get(this.apiEndpoints.get((type === 'open' ? 'reports.open' : (type === 'closed' ? 'reports.closed' : 'reports.all'))), {missing_start: start})
        .subscribe((response: ServerResponse): void => {
          if (typeof response.data !== 'undefined') {
            o.next(<ServerResponse>response);
          } else {
            o.next(false);
          }
        }, (): void => {
          o.next(false);
        });

      return (): void => {
        subscription.unsubscribe();
      };
    });
  }

  count(): Observable<MenuReportCounts | boolean> {
    return new Observable<MenuReportCounts | boolean>((o: Subscriber<MenuReportCounts | boolean>) => {
      const subscription: Subscription = this.api.get(this.apiEndpoints.get('reports.count'), null, true)
        .subscribe((response: ServerResponse): void => {
          if (typeof response.data !== 'undefined') {
            o.next(<MenuReportCounts>response.data);
          } else {
            o.next(false);
          }
        }, (): void => {
          o.next(false);
        });

      return (): void => {
        subscription.unsubscribe();
      };
    });
  }

  get(id: number): Observable<Report | boolean> {
    return new Observable<Report | boolean>((o: Subscriber<Report | boolean>) => {
      const subscription: Subscription = this.api.get(this.apiEndpoints.get('reports.read', {':id': id}))
        .subscribe((response: ServerResponse): void => {
          if (typeof response.data !== 'undefined') {
            o.next(<Report>response.data);
          } else {
            o.next(false);
          }
        }, (): void => {
          o.next(false);
        });

      return (): void => {
        subscription.unsubscribe();
      };
    });
  }

  create(formData: any): Observable<ServerResponse> {
    return this.api.post(this.apiEndpoints.get('reports.create'), formData);
  }

  update(id: number, formData: any): Observable<ServerResponse> {
    return this.api.put(this.apiEndpoints.get('reports.update', {':id': id}), formData);
  }

  delete(id: number): Observable<boolean> {
    return new Observable<boolean>((o: Subscriber<boolean>) => {
      const subscription: Subscription = this.api.delete(this.apiEndpoints.get('reports.delete', {':id': id}))
        .subscribe((response: ServerResponse): void => {
            if (response) {
              this.reportDeletedId.next(id);
              o.next(true);
            } else {
              o.next(false);
            }
          },
          (): void => o.next(false));

      return (): void => {
        subscription.unsubscribe();
      };
    });
  }

  restore(id: number): Observable<boolean> {
    return new Observable<boolean>((o: Subscriber<boolean>) => {
      const subscription: Subscription = this.api.post(this.apiEndpoints.get('reports.restore', {':id': id}))
        .subscribe((response: ServerResponse): void => {
            if (response) {
              o.next(true);
            } else {
              o.next(false);
            }
          },
          (): void => o.next(false));

      return (): void => {
        subscription.unsubscribe();
      };
    });
  }

  downloadAttachment(id: number, attachment_id: number): void {
    this.api.download(this.apiEndpoints.get('reports.download-attachment', {':id': id, ':attachment_id': attachment_id}));
  }

  downloadHandlingAttachment(id: number, attachment_id: number): void {
    this.api.download(this.apiEndpoints.get('reports.download-handling-attachment', {':id': id, ':attachment_id': attachment_id}));
  }

  deleteAttachment(id: number, attachment_id: number): Observable<boolean> {
    return new Observable<boolean>((o: Subscriber<boolean>) => {
      const subscription: Subscription = this.api.delete(this.apiEndpoints.get('reports.download-attachment', {':id': id, ':attachment_id': attachment_id}))
        .subscribe((response: ServerResponse): void => {
            if (response) {
              o.next(true);
            } else {
              o.next(false);
            }
          },
          (): void => {
            o.next(false);
          });

      return (): void => {
        subscription.unsubscribe();
      };
    });
  }

  suggestOrganisation(id: number): Observable<SuggestOrganisation> {
    return new Observable<SuggestOrganisation>((o: Subscriber<SuggestOrganisation>) => {
      const subscription: Subscription = this.api.get(this.apiEndpoints.get('reports.suggest-organisation', {':id': id}))
        .subscribe((response: ServerResponse): void => {
          if (typeof response.data !== 'undefined') {
            o.next(<SuggestOrganisation>response.data);
          }
        });

      return (): void => {
        subscription.unsubscribe();
      };
    });
  }

  assignToOrganisation(id: number, formData: any): Observable<ServerResponse> {
    return this.api.post(this.apiEndpoints.get('reports.assign-to-organisation', {':id': id}), formData);
  }

  assignToDepartment(id: number, formData: any): Observable<ServerResponse> {
    return this.api.post(this.apiEndpoints.get('reports.assign-to-department', {':id': id}), formData);
  }

  assignToMyself(id: number): Observable<ServerResponse> {
    return this.api.post(this.apiEndpoints.get('reports.assign-to-myself', {':id': id}));
  }

  forward(id: number, formData: any): Observable<ServerResponse> {
    return this.api.post(this.apiEndpoints.get('reports.forward', {':id': id}), formData);
  }

  reassignToOrganisation(id: number, formData: any): Observable<ServerResponse> {
    return this.api.post(this.apiEndpoints.get('reports.reassign-to-organisation', {':id': id}), formData);
  }

  history(id: number): Observable<ServerResponse> {
    return this.api.get(this.apiEndpoints.get('reports.history', {':id': id}));
  }

  copy(id: number): Observable<Report> {
    return new Observable<Report>((o: Subscriber<Report>) => {
      const subscription: Subscription = this.api.get(this.apiEndpoints.get('reports.copy', {':id': id}))
        .subscribe((response: ServerResponse): void => {
          if (typeof response.data !== 'undefined') {
            o.next(<Report>response.data);
          } else {
            o.next(undefined);
          }
        }, (): void => {
          o.next(undefined);
        });

      return (): void => {
        subscription.unsubscribe();
      };
    });
  }

  search(): Observable<Report[]> {
    return new Observable<Report[]>((o: Subscriber<Report[]>) => {
      const subscription: Subscription = this.api.get(this.apiEndpoints.get('reports.search'))
        .subscribe((response: ServerResponse): void => {
          if (typeof response.data !== 'undefined') {
            o.next(<Report[]>response.data);
          }
        });

      return (): void => {
        subscription.unsubscribe();
      };
    });
  }

  searchExport(): Observable<ServerResponse> {
    return this.api.get(this.apiEndpoints.get('reports.search-download'));
  }

  export(formData: any, fileType: string = 'csv'): Observable<ServerResponse> {
    return this.api.post(this.apiEndpoints.get('reports.export', {':fileType': fileType}), formData);
  }

  seed(formData: any): Observable<ServerResponse> {
    return this.api.post(this.apiEndpoints.get('reports.seed'), formData);
  }

  reopen(id: number, formData: any): Observable<ServerResponse> {
    return this.api.post(this.apiEndpoints.get('reports.reopen', {':id': id}), formData);
  }

  resendOpenConfirmation(id: number): Observable<ServerResponse> {
    return this.api.post(this.apiEndpoints.get('reports.resend-open-confirmation', {':id': id}));
  }

  updateVisibility(id: number, formData: any): Observable<ServerResponse> {
    return this.api.put(this.apiEndpoints.get('reports.update-visibility', {':id': id}), formData);
  }
}
