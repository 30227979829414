<fury-alert-box *ngIf="isStaging" class="warn-absolute" style="border-radius:0;margin:0;">
  This is staging environment!
</fury-alert-box>
<fury-alert-box *ngIf="!api.isOnline" class="warn-absolute" style="border-radius:0;margin:0;">
  Uw bent momenteel offline, er is een internet verbinding nodig om met de applicatie te werken.
</fury-alert-box>
<fury-alert-box *ngIf="badConnection" class="warn-absolute" style="border-radius:0;margin:0;">
  Om goed te werken met de applicatie is er goede internet verbinding nodig, controleer uw internet verbinding.
</fury-alert-box>
<fury-alert-box *ngIf="layoutService.appUpdatePending" class="warn-absolute" style="border-radius:0;margin:0;">
  <div fxFlex fxLayout="column" fxLayout.gt-xs="row" fxLayoutAlign="center center"
       fxLayoutAlign.gt-xs="space-between center">
    Er is een nieuwe versie van de applicatie beschikbaar, het is belangrijk om snel mogelijk deze update uit te voeren.
    <div fxHide.gt-xs style="padding: 5px;"></div>
    <button (click)="reloadApp()" color="black" mat-raised-button>
      <mat-icon>refresh</mat-icon>
      Herladen
    </button>
  </div>
</fury-alert-box>

<fury-backdrop #backdrop="furyBackdrop"></fury-backdrop>
<fury-backdrop #clientPortalBackdrop="furyBackdrop"></fury-backdrop>

<fury-report-panel #reportPanel="furyReportPanel" [backdrop]="backdrop" [opened]="reportPanelService.open$ | async">
  <ng-template #reportPanelPlaceholder></ng-template>
</fury-report-panel>

<fury-client-portal-report-panel #clientPortalReportPanel="furyClientPortalReportPanel" [backdrop]="clientPortalBackdrop"
                                 [opened]="reportPanelService.openClientPortalView$ | async">
  <ng-template #clientPortalReportPanelPlaceholder></ng-template>
</fury-client-portal-report-panel>

<fury-report-print></fury-report-print>

<div class="container" fxLayout="column">
  <mat-sidenav-container autosize fxFlex>

    <!-- SIDENAV -->
    <mat-sidenav (closedStart)="closeSidenav()"
                 (openedStart)="openSidenav()"
                 [mode]="sidenavMode$ | async"
                 [opened]="sidenavOpen$ | async"
                 class="fury-collapsable-sidenav">
      <fury-sidenav [collapsed]="sidenavCollapsed$ | async"></fury-sidenav>
    </mat-sidenav>
    <!-- END SIDENAV -->

    <mat-sidenav-content class="content-container" fxLayout="column">
      <!-- TOOLBAR -->
      <fury-toolbar (openSidenav)="openSidenav()" fxFlex="none"></fury-toolbar>
      <!-- END TOOLBAR -->

      <!-- CONTENT -->
      <div [class.scroll-disabled]="scrollDisabled$ | async"
           #scrollBar
           (scrolled)="layoutService.onScrollDown.next($event)"
           [infiniteScrollDisabled]="layoutService.infiniteScrollDisabled"
           [infiniteScrollDistance]="1"
           [scrollWindow]="false"
           infinite-scroll
           cdkScrollable
           class="content"
           fxFlex="auto"
           fxLayout="column">

        <fury-loading-overlay [isLoading]="layoutService.isLoading" top="true"></fury-loading-overlay>

        <div class="content-inner" fxFlex="auto">
          <router-outlet></router-outlet>
        </div>
      </div>
      <!-- END CONTENT -->
    </mat-sidenav-content>
  </mat-sidenav-container>
</div>
