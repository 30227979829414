import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { WebSocketPermissions } from '../../interfaces/web-socket-permissions';
import { AuthenticationService } from '../base/authentication.service';
import { User } from '../../interfaces/routering/user';
import { environment } from '../../../environments/environment';
import { Report } from '../../interfaces/report/report';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { ApiService } from '../base/api.service';
import { ServerRouteProperties } from '../../interfaces/base/server-route-properties';
import * as _ from 'lodash';
import { ServerResponse } from '../../interfaces/base/server.response';
import { ReportService } from './report.service';
import { take } from 'rxjs/operators';

@Injectable()

export class ReportWebhookService {

  public reportUpdated: Subject<Report> = new Subject<Report>();
  public reportDeleted: Subject<Report> = new Subject<Report>();
  public reportCount: Subject<'add' | 'remove'> = new Subject<'add' | 'remove'>();

  private highlightReports: number[] = [];
  private _highlightReports: BehaviorSubject<number[]> = new BehaviorSubject<number[]>(null);
  public highlightReports$: Observable<number[]> = this._highlightReports as Observable<number[]>;

  // subscription for MKC users for newly created reports
  private webSocket: WebSocketPermissions = {
    channel: 'reports',
    events: [
      '.created',
      '.updated',
      '.deleted',
      '.reopened',
    ],
    permissions: [
      'can-update-reports',
      'can-print-reports',
      'can-view-reports',
      'can-delete-reports',
      'can-forward-reports',
      'can-assign-new-reports',
      'can-reject-reports',
      'can-resolve-reports',
      'can-read-reports-from-all-organisations',
      'can-edit-closed-reports',
      'can-reopen-closed-reports',
      'can-bulk-handle-reports',
      'can-copy-reports',
      'can-see-unassigned-reports',
      'can-see-deleted-reports',
    ]
  };

  private user: User = null;
  private routeParams: ServerRouteProperties = {
    active: '',
    direction: '',
    filters: [],
  };
  private webSocketSubscriptions: any = [];

  constructor(private authenticationService: AuthenticationService,
              private api: ApiService,
              private reportService: ReportService
  ) {
    this.reportCount.subscribe((a: 'add' | 'remove'): void => {
      if (a === 'add') {
        this.api.length++;
        this.api.matPaginator.length = this.api.length;
      } else if (a === 'remove') {
        this.api.length--;
        this.api.matPaginator.length = this.api.length;
      }
    });

    this.reportService.reportOpenedId.subscribe((id: number): void => {
      this.removeHighlightedReport(id);
    });

    this.api.preSetFilters.pipe(take(1)).subscribe((): void => {
      if (this.api.filterPageName === 'reports-open') {
        this.routeParams = JSON.parse(JSON.stringify(this.api.routeParams));
      }
    });

    this.api.filterUpdated.subscribe((): void => {
      if (this.api.filterPageName === 'reports-open') {
        this.routeParams = JSON.parse(JSON.stringify(this.api.routeParams));
      }
    });
  }

  init(dataSource: MatTableDataSource<Report> = null, paginator: MatPaginator): void {
    this.authenticationService.user$.subscribe((user: User): void => {
      if (user) {
        this.user = user;
        const i: number = 0;
        const interval: NodeJS.Timeout = setInterval((): void => {
          if (typeof window.Echo !== 'undefined') {
            if (!environment.production || this.user && this.user.role === 'super-admin') {
              console.log('ReportWebhookService ready');
            }

            this.destroy();

            if (this.webSocketSubscriptions.indexOf('reports.created') === -1) {
              if (!environment.production || this.user && this.user.role === 'super-admin') {
                console.log('WebSocket startSubscription:', {channel: this.webSocket.channel, event: '.created'});
              }
              window.Echo.private(this.webSocket.channel).listen('.created', (report: Report): void => {
                if (!environment.production || this.user && this.user.role === 'super-admin') {
                  console.log('WebSocket received event for "' + this.webSocket.channel + '.created" channel: ', report);
                }

                this.processData(user, paginator, dataSource, report, 'created');
              });
              this.webSocketSubscriptions.push('reports.created');
            }

            if (this.webSocketSubscriptions.indexOf('reports.updated') === -1) {
              if (!environment.production || this.user && this.user.role === 'super-admin') {
                console.log('WebSocket startSubscription:', {channel: this.webSocket.channel, event: '.updated'});
              }
              window.Echo.private(this.webSocket.channel).listen('.updated', (report: Report): void => {
                if (!environment.production || this.user && this.user.role === 'super-admin') {
                  console.log('WebSocket received event for "' + this.webSocket.channel + '.updated" channel: ', report);
                }

                this.processData(user, paginator, dataSource, report, 'updated');
              });
              this.webSocketSubscriptions.push('reports.updated');
            }

            if (this.webSocketSubscriptions.indexOf('reports.resolved') === -1) {
              if (!environment.production || this.user && this.user.role === 'super-admin') {
                console.log('WebSocket startSubscription:', {channel: this.webSocket.channel, event: '.resolved'});
              }
              window.Echo.private(this.webSocket.channel).listen('.resolved', (report: Report): void => {
                if (!environment.production || this.user && this.user.role === 'super-admin') {
                  console.log('WebSocket received event for "' + this.webSocket.channel + '.resolved" channel: ', report);
                }

                this.processData(user, paginator, dataSource, report, 'resolved');
              });
              this.webSocketSubscriptions.push('reports.resolved');
            }

            if (this.webSocketSubscriptions.indexOf('reports.rejected') === -1) {
              if (!environment.production || this.user && this.user.role === 'super-admin') {
                console.log('WebSocket startSubscription:', {channel: this.webSocket.channel, event: '.rejected'});
              }
              window.Echo.private(this.webSocket.channel).listen('.rejected', (report: Report): void => {
                if (!environment.production || this.user && this.user.role === 'super-admin') {
                  console.log('WebSocket received event for "' + this.webSocket.channel + '.rejected" channel: ', report);
                }

                this.processData(user, paginator, dataSource, report, 'rejected');
              });
              this.webSocketSubscriptions.push('reports.rejected');
            }

            if (this.webSocketSubscriptions.indexOf('reports.deleted') === -1) {
              if (!environment.production || this.user && this.user.role === 'super-admin') {
                console.log('WebSocket startSubscription:', {channel: this.webSocket.channel, event: '.deleted'});
              }
              window.Echo.private(this.webSocket.channel).listen('.deleted', (report: Report): void => {
                if (!environment.production || this.user && this.user.role === 'super-admin') {
                  console.log('WebSocket received event for "' + this.webSocket.channel + '.deleted" channel: ', report);
                }

                this.processData(user, paginator, dataSource, report, 'deleted');
              });
              this.webSocketSubscriptions.push('reports.deleted');
            }

            clearInterval(interval);
          } else {
            console.log('ReportWebhookService not ready');
          }
          if (i > 100) {
            clearInterval(interval);
          }
        }, 100);
      }
    });
  }

  processData(user: User, paginator: MatPaginator, dataSource: MatTableDataSource<Report>, report: Report, type: 'created' | 'updated' | 'resolved' | 'rejected' | 'reopened' | 'deleted'): void {
    if (!environment.production || this.user && this.user.role === 'super-admin') {
      console.log(this.routeParams);
    }

    const canReportBeAdded: boolean = this.canReportBeAdded(dataSource, report);
    if (!environment.production || this.user && this.user.role === 'super-admin') {
      console.log('canReportBeAdded:', canReportBeAdded);
    }

    // add new report
    if (type === 'created' && paginator.pageIndex === 0 && (
      user.permissions.some((p: string): boolean => ['can-read-reports-from-all-organisations', 'can-see-unassigned-reports'].includes(p)) ||
      user.role === 'super-admin'
    )) {
      // check if report can even be added because of filters
      if (canReportBeAdded && this.canReportBeAddedOnCurrentPage(dataSource, report)) {
        const index: number = dataSource.data.findIndex((d: Report): boolean => d.id === report.id);
        if (index === -1) {
          this.reportCount.next('add');

          // if enough reports, remove last one
          if (dataSource.data.length >= this.api.pageSize) {
            if (!environment.production || this.user && this.user.role === 'super-admin') {
              console.log('removing last report from list');
            }
            dataSource.data.splice(-1, 1);
          }

          // decide where to add a report
          if (this.routeParams.direction === 'desc' && dataSource.data.length >= this.api.pageSize) {
            if (!environment.production || this.user && this.user.role === 'super-admin') {
              console.log('adding report as first in the list');
            }
            dataSource.data.unshift(report);
          } else {
            if (!environment.production || this.user && this.user.role === 'super-admin') {
              console.log('adding report as last in the list');
            }
            dataSource.data.push(report);
          }
        }
      }
    }

    // update existing report
    if ((type === 'updated' || type === 'reopened') && (user.organisation || user.role === 'super-admin')) {
      this.reportUpdated.next(report);

      if (!report.organisation_id && (user.permissions.includes('can-see-unassigned-reports') || user.permissions.includes('can-read-reports-from-all-organisations') || user.role === 'super-admin')) {
        // if report exists, update it. if it doesn't exist, add it
        const index: number = dataSource.data.findIndex((d: Report): boolean => d.id === report.id);
        if (index !== -1) {
          if (!environment.production || this.user && this.user.role === 'super-admin') {
            console.log('report found, replace the data');
          }
          dataSource.data[index] = report;
        } else {
          if (canReportBeAdded && this.canReportBeAddedOnCurrentPage(dataSource, report)) {
            this.reportCount.next('add');

            if (dataSource.data.length >= this.api.pageSize) {
              if (!environment.production || this.user && this.user.role === 'super-admin') {
                console.log('removing last report from list');
              }
              dataSource.data.splice(-1, 1);
            }

            // decide where to add a report
            if ((this.routeParams.direction === 'desc' || this.routeParams.direction === '' || this.routeParams.direction === null) && dataSource.data.length >= this.api.pageSize) {
              if (!environment.production || this.user && this.user.role === 'super-admin') {
                console.log('adding report as first in the list');
              }
              dataSource.data.unshift(report);
            } else {
              if (!environment.production || this.user && this.user.role === 'super-admin') {
                console.log('adding report as last in the list');
              }
              dataSource.data.push(report);
            }
          }
        }
      } else {
        // if user can see reports from all organisations, find it and update it
        // if user can't see reports from all organisations and organisation matches, find it and update it
        // if user can't see reports from all organisations and organisation does not match, find it, deleted it and get replacement

        if (!user.permissions.includes('can-read-reports-from-all-organisations') && user.organisation.id === report.organisation_id ||
          user.permissions.includes('can-read-reports-from-all-organisations') ||
          user.role === 'super-admin') {
          const index: number = dataSource.data.findIndex((d: Report): boolean => d.id === report.id);
          if (index !== -1) {
            if (!environment.production || this.user && this.user.role === 'super-admin') {
              console.log('report found, replace the data');
            }
            dataSource.data[index] = report;
          } else {
            // check if report can even be added because of filters
            if (canReportBeAdded && this.canReportBeAddedOnCurrentPage(dataSource, report)) {
              this.reportCount.next('add');

              if (dataSource.data.length >= this.api.pageSize) {
                if (!environment.production || this.user && this.user.role === 'super-admin') {
                  console.log('removing last report from list');
                }
                dataSource.data.splice(-1, 1);
              }

              // decide where to add a report
              if ((this.routeParams.direction === 'desc' || this.routeParams.direction === '' || this.routeParams.direction === null) && dataSource.data.length >= this.api.pageSize) {
                if (!environment.production || this.user && this.user.role === 'super-admin') {
                  console.log('adding report as first in the list');
                }
                dataSource.data.unshift(report);
              } else {
                if (!environment.production || this.user && this.user.role === 'super-admin') {
                  console.log('adding report as last in the list');
                }
                dataSource.data.push(report);
              }
            }
          }
        } else {
          const index: number = dataSource.data.findIndex((d: Report): boolean => d.id === report.id);
          if (index !== -1) {
            if (!environment.production || this.user && this.user.role === 'super-admin') {
              console.log('report found, remove it');
            }
            dataSource.data.splice(index, 1);

            this.reportCount.next('remove');

            this.getMissingData(dataSource);
          }
        }
      }

      // highlight report
      // - if report was assigned to specific user, highlight it for that user only
      // - if report was assigned to specific department, highlight it for that department users
      // - if report was assigned to organisation only, highlight it for all users from that organisation
      // - if report was assigned to user through in behandeling, do not highlight it
      if (typeof report.assigned_id !== 'undefined' && typeof report.assigned_type !== 'undefined' && typeof report.organisation_id !== 'undefined') {
        if (report.assigned_id !== null && report.assigned_type !== null && (
          report.assigned_type === 'user' && report.assigned_id === user.id || report.assigned_type === 'department' && report.assigned_id === user.department_id
        )) {
          this.addHighlightedReport(report.id);
        } else if (typeof report.organisation_id !== 'undefined' && report.organisation_id !== null &&
          typeof user.organisation !== 'undefined' && user.organisation !== null && report.organisation_id === user.organisation.id
          && report.assigned_id === null
        ) {
          this.addHighlightedReport(report.id);
        } else {
          this.removeHighlightedReport(report.id);
        }
      } else {
        this.removeHighlightedReport(report.id);
      }
    }

    // remove processed report from list
    if (type === 'resolved' || type === 'rejected') {
      const index: number = dataSource.data.findIndex((d: Report): boolean => d.id === report.id);
      if (index !== -1) {
        if (!environment.production || this.user && this.user.role === 'super-admin') {
          console.log('report found, remove it');
        }
        dataSource.data.splice(index, 1);

        this.reportCount.next('remove');

        this.getMissingData(dataSource);

        this.removeHighlightedReport(report.id);
      }
    }

    // delete existing report
    if (type === 'deleted') {
      if (user.permissions.includes('can-see-deleted-reports') || user.role === 'super-admin') {
        const index: number = dataSource.data.findIndex((d: Report): boolean => d.id === report.id);
        if (index !== -1) {
          if (!environment.production || this.user && this.user.role === 'super-admin') {
            console.log('report found, replace the data');
          }
          dataSource.data[index] = report;
        }

        this.reportDeleted.next(report);
      } else {
        const index: number = dataSource.data.findIndex((d: Report): boolean => d.id === report.id);
        if (index !== -1) {
          if (!environment.production || this.user && this.user.role === 'super-admin') {
            console.log('report found, remove it');
          }
          dataSource.data.splice(index, 1);

          this.reportCount.next('remove');

          this.getMissingData(dataSource);

          this.removeHighlightedReport(report.id);
        }
      }
    }

    if (canReportBeAdded) {
      dataSource.data.sort((a: Report, b: Report): number => {
        let a1: string | number = null,
          b1: string | number = null,
          sortByReceivedAt: boolean = false;
        if (this.routeParams.active === 'received_at' || this.routeParams.active === '' || this.routeParams.active === null) {
          a1 = a.received_at;
          b1 = b.received_at;
        } else if (this.routeParams.active === 'number') {
          a1 = parseInt(a[this.routeParams.active].replace('M', '').replace('-', ''), 10);
          b1 = parseInt(b[this.routeParams.active].replace('M', '').replace('-', ''), 10);
        } else if (this.routeParams.active === 'head_category_id') {
          a1 = _.get(a, 'categories[0].head_category.name', null);
          b1 = _.get(b, 'categories[0].head_category.name', null);
          sortByReceivedAt = true;
        } else if (this.routeParams.active === 'main_category_id') {
          a1 = _.get(a, 'categories[0].main_category.name', null);
          b1 = _.get(b, 'categories[0].main_category.name', null);
          sortByReceivedAt = true;
        } else if (this.routeParams.active === 'complaint_location') {
          a1 = _.get(a, 'complaint.streetname', null);
          a1 = (!_.isEmpty(a1) ? a1 + ' ' + _.get(a, 'complaint.house_number', null) + ' ' + _.get(a, 'complaint.house_number_addition', null) : a1);
          b1 = _.get(b, 'complaint.streetname', null);
          b1 = (!_.isEmpty(b1) ? b1 + ' ' + _.get(b, 'complaint.house_number', null) + ' ' + _.get(b, 'complaint.house_number_addition', null) : b1);
        } else if (this.routeParams.active === 'complaint_municipality') {
          a1 = _.get(a, 'complaint.municipality', null);
          b1 = _.get(b, 'complaint.municipality', null);
        } else if (['causer_name', 'assigned_to'].indexOf(this.routeParams.active) !== -1) {
          a1 = a[this.routeParams.active].toUpperCase();
          b1 = b[this.routeParams.active].toUpperCase();
          sortByReceivedAt = true;
        } else if (this.routeParams.active === 'organisation_id') {
          a1 = _.get(a, 'organisation.name', null);
          b1 = _.get(b, 'organisation.name', null);
          sortByReceivedAt = true;
        } else if (this.routeParams.active === 'status_id') {
          a1 = _.get(a, 'status.display_name', null);
          b1 = _.get(b, 'status.display_name', null);
          sortByReceivedAt = true;
        }

        if (sortByReceivedAt) {
          if (this.routeParams.direction === 'desc' || this.routeParams.direction === '' || this.routeParams.direction === null) {
            return (b1 < a1 ? -1 : (b1 > a1 ? 1 : (b.received_at < a.received_at ? -1 : (b.received_at > a.received_at ? 1 : 0))));
          }

          return (a1 < b1 ? -1 : (a1 > b1 ? 1 : (b.received_at < a.received_at ? -1 : (b.received_at > a.received_at ? 1 : 0))));
        } else {
          if (this.routeParams.direction === 'desc' || this.routeParams.direction === '' || this.routeParams.direction === null) {
            return (b1 < a1 ? -1 : (b1 > a1 ? 1 : 0));
          }

          return (a1 < b1 ? -1 : (a1 > b1 ? 1 : 0));
        }
      });

      dataSource.data = dataSource.data;
    }
  }

  canReportBeAdded(dataSource: MatTableDataSource<Report>, report: Report): boolean {
    const last: Report = _.last(dataSource.data);
    let response: boolean = true;

    // check if sorting is applied
    // if applied to text columns, get start and end 'cut-off' strings
    // check if report falls between those strings
    if (response) {
      if (typeof this.routeParams.active !== 'undefined' && this.routeParams.active !== null && this.routeParams.active !== '') {
        if (this.routeParams.active === 'received_at' && (
          this.routeParams.direction === 'asc' && report.received_at > _.get(last, 'received_at', null) && dataSource.data.length >= this.api.pageSize
        )) {
          response = false;
        }
        if (this.routeParams.active === 'number' && typeof last !== 'undefined' && (
          this.routeParams.direction === 'asc' && parseInt(report.number.replace('M', '').replace('-', ''), 10) > parseInt(last.number.replace('M', '').replace('-', ''), 10)
        )) {
          response = false;
        }
        if (this.routeParams.active === 'head_category_id' && _.get(report, 'categories.0.head_category.name', null) > _.get(last, 'categories.0.head_category.name', null)) {
          response = false;
        }
        if (this.routeParams.active === 'main_category_id' && _.get(report, 'categories.0.main_category.name', null) > _.get(last, 'categories.0.main_category.name', null)) {
          response = false;
        }
        if (this.routeParams.active === 'complaint_location' && (
          this.routeParams.direction === 'desc' && _.get(report, 'complaint.streetname', null) < _.get(last, 'complaint.streetname', null) ||
          this.routeParams.direction === 'asc' && _.get(report, 'complaint.streetname', null) > _.get(last, 'complaint.streetname', null)
        )) {
          response = false;
        }
        if (this.routeParams.active === 'complaint_municipality' && (
          this.routeParams.direction === 'desc' && _.get(report, 'complaint.municipality', null).toLowerCase() < _.get(last, 'complaint.municipality', null) ||
          this.routeParams.direction === 'asc' && _.get(report, 'complaint.municipality', null).toLowerCase() > _.get(last, 'complaint.municipality', null)
        )) {
          response = false;
        }
        if (this.routeParams.active === 'causer_name' && typeof last !== 'undefined' && (
          this.routeParams.direction === 'desc' && report.causer_name.toLowerCase() < last.causer_name.toLowerCase() ||
          this.routeParams.direction === 'asc' && report.causer_name.toLowerCase() > last.causer_name.toLowerCase()
        )) {
          response = false;
        }
        if (this.routeParams.active === 'organisation_id' && (
          this.routeParams.direction === 'desc' && _.get(report, 'organisation.name') < _.get(last, 'organisation.name') ||
          this.routeParams.direction === 'asc' && _.get(report, 'organisation.name') > _.get(last, 'organisation.name')
        )) {
          response = false;
        }
        if (this.routeParams.active === 'assigned_to' && (
          this.routeParams.direction === 'desc' && _.get(report, 'assigned_to') < _.get(last, 'assigned_to') ||
          this.routeParams.direction === 'asc' && _.get(report, 'assigned_to') > _.get(last, 'assigned_to')
        )) {
          response = false;
        }
        if (this.routeParams.active === 'status_id' && (
          this.routeParams.direction === 'desc' && _.get(report, 'status.display_name') < _.get(last, 'status.display_name') ||
          this.routeParams.direction === 'asc' && _.get(report, 'status.display_name') > _.get(last, 'status.display_name')
        )) {
          response = false;
        }
      }
      if (!environment.production || this.user && this.user.role === 'super-admin') {
        console.log('sorting judgement: ', response);
      }
    }

    // check if filtering is applied
    // if applied, check start and end cut-off strings
    if (response && this.routeParams.filters.length) {
      this.routeParams.filters.forEach(filter => {
        if (filter.field === 'received_at') {
          const date: string[] = (<string>filter.value).split('!');
          if (date[0] > report.received_at || date[1] < report.received_at) {
            response = false;
          }
        }
        if (filter.field === 'number' && report.number.indexOf(filter.value.toString()) !== 0) {
          response = false;
        }
        if (filter.field === 'head_category_id' && _.get(report, 'categories.0.head_category_id', null) !== filter.value) {
          response = false;
        }
        if (filter.field === 'main_category_id' && _.get(report, 'categories.0.main_category_id', null) !== filter.value) {
          response = false;
        }
        if (filter.field === 'complaint_location' && (
          (_.get(report, 'complaint.streetname') !== null && _.get(report, 'complaint.streetname', '').toLowerCase().indexOf((<string>filter.value).toLowerCase()) === -1) &&
          (_.get(report, 'complaint.postalcode') !== null && _.get(report, 'complaint.postalcode', '').toLowerCase().indexOf((<string>filter.value).toLowerCase()) === -1) &&
          (_.get(report, 'complaint.house_number') !== null && _.get(report, 'complaint.house_number', '').toLowerCase().indexOf((<string>filter.value).toLowerCase()) === -1) &&
          (_.get(report, 'complaint.city') !== null && _.get(report, 'complaint.city', '').toLowerCase().indexOf((<string>filter.value).toLowerCase()) === -1) &&
          (_.get(report, 'complaint.postalcode') !== null && _.get(report, 'complaint.house_number') !== null && (_.get(report, 'complaint.postalcode', '').toLowerCase() + ' ' + _.get(report, 'complaint.house_number')).indexOf((<string>filter.value).toLowerCase()) === -1) &&
          (_.get(report, 'complaint.streetname') !== null && _.get(report, 'complaint.house_number') !== null && (_.get(report, 'complaint.streetname', '').toLowerCase() + ' ' + _.get(report, 'complaint.house_number')).indexOf((<string>filter.value).toLowerCase()) === -1)
        )) {
          response = false;
        }
        if (filter.field === 'complaint_municipality' && (_.get(report, 'complaint.municipality') !== null && _.get(report, 'complaint.municipality', '').toLowerCase().indexOf((<string>filter.value).toLowerCase()) === -1)) {
          response = false;
        }
        if (filter.field === 'causer_name' && report.causer_name.toLowerCase().indexOf((<string>filter.value).toLowerCase()) === -1) {
          response = false;
        }
        if (filter.field === 'organisation_id' && (filter.value === 'none' && report.organisation_id !== null || filter.value !== 'none' && report.organisation_id !== filter.value)) {
          response = false;
        }
        if (filter.field === 'assigned_to' && (filter.value === 'none' && report.assigned_id !== null || filter.value !== 'none' && report.assigned_id !== filter.value)) {
          response = false;
        }
        if (filter.field === 'status_id' && report.status_id !== filter.value) {
          response = false;
        }
      });
    }
    if (!environment.production || this.user && this.user.role === 'super-admin') {
      console.log('filters judgement: ', response);
    }

    return response;
  }

  canReportBeAddedOnCurrentPage(dataSource: MatTableDataSource<Report>, report: Report): boolean {
    const first: Report = _.first(dataSource.data),
      last: Report = _.last(dataSource.data);
    let response: boolean = true;

    if (!environment.production || this.user && this.user.role === 'super-admin') {
      console.log(this.routeParams);
      console.log(report, dataSource.data, last);
    }

    // check if report should be shown on current page
    if (response && typeof first !== 'undefined' && typeof last !== 'undefined') {
      if (this.routeParams.page === 1) {
        if (this.routeParams.direction === 'desc' || this.routeParams.direction === '' || this.routeParams.direction === null) {
          if (!environment.production || this.user && this.user.role === 'super-admin') {
            console.log('report: ', report.received_at);
            console.log('last: ', last.received_at);
          }
          if (last.received_at > report.received_at) {
            if (!environment.production || this.user && this.user.role === 'super-admin') {
              console.log('11');
            }
            response = false;
          }
        } else {
          if (!environment.production || this.user && this.user.role === 'super-admin') {
            console.log('first: ', first.received_at);
            console.log('report: ', report.received_at);
          }
          if (first.received_at < report.received_at) {
            if (!environment.production || this.user && this.user.role === 'super-admin') {
              console.log('12');
            }
            response = false;
          }
        }
      } else {
        if (!environment.production || this.user && this.user.role === 'super-admin') {
          console.log('first: ', first.received_at);
          console.log('report: ', report.received_at);
          console.log('last: ', last.received_at);
        }
        if (this.routeParams.direction === 'desc' || this.routeParams.direction === '' || this.routeParams.direction === null) {
          if (first.received_at < report.received_at || last.received_at > report.received_at) {
            if (!environment.production || this.user && this.user.role === 'super-admin') {
              console.log('13');
            }
            response = false;
          }
        } else {
          if (first.received_at > report.received_at || last.received_at < report.received_at) {
            if (!environment.production || this.user && this.user.role === 'super-admin') {
              console.log('14');
            }
            response = false;
          }
        }
      }
    }
    if (!environment.production || this.user && this.user.role === 'super-admin') {
      console.log('page judgement: ', response);
    }

    return response;
  }

  getMissingData(dataSource: MatTableDataSource<Report>): void {
    const start: number = dataSource.data.length,
      filterPageName: string = this.api.filterPageName;

    this.api.filterPageName = null;
    this.reportService.missing('open', start).subscribe((response: ServerResponse): void => {
      if (typeof response.data !== 'undefined' && typeof response.data[0] !== 'undefined') {
        dataSource.data.push(response.data[0]);

        dataSource.data = dataSource.data;
      } else {
        this.reportCount.next('remove');
      }

      this.api.filterPageName = filterPageName;
    });
  }

  addHighlightedReport(id: number): void {
    const index: number = this.highlightReports.findIndex((d: number): boolean => d === id);

    if (index === -1) {
      this.highlightReports.push(id);
      this._highlightReports.next(this.highlightReports);
    }
  }

  removeHighlightedReport(id: number): void {
    const index: number = this.highlightReports.findIndex((d: number): boolean => d === id);

    if (index !== -1) {
      this.highlightReports.splice(index, 1);
      this._highlightReports.next(this.highlightReports);
    }
  }

  clear(): void {
    this.reportUpdated.next(null);
    this.reportDeleted.next(null);
  }

  destroy(): void {
    if (typeof window.Echo !== 'undefined') {
      this.webSocketSubscriptions.forEach((event: string): void => {
        const data: string[] = event.split('.');
        data[1] = '.' + data[1];
        if (!environment.production || this.user && this.user.role === 'super-admin') {
          console.log('WebSocket stopSubscription:', {channel: data[0], event: data[1]});
        }

        window.Echo.private(data[0]).stopListening(data[1]);
      });

      this.webSocketSubscriptions = [];
    }
  }
}
