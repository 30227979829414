import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { delay } from 'rxjs/operators';

@Injectable()

export class ReportPanelService {

  public editorType: 'create' | 'update' = 'update';

  public nextReportId: BehaviorSubject<number | boolean> = new BehaviorSubject<number>(null);
  public clientPortalNewReportId: BehaviorSubject<number | boolean> = new BehaviorSubject<number>(null);

  private _openSubject: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  open$: Observable<boolean> = this._openSubject.asObservable().pipe(delay(0));
  public isClientPortalViewOpen: boolean = false;
  private _openClientPortalViewSubject: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  public isOpen: boolean = false;
  openClientPortalView$: Observable<boolean> = this._openClientPortalViewSubject.asObservable().pipe(delay(0));

  constructor() {
  }

  open(report_id?: number | string): void {
    this.editorType = report_id ? 'update' : 'create';
    this.nextReportId.next(report_id ? parseInt(<string>report_id, 10) : null);
    this._openSubject.next(true);
    this.isOpen = true;
  }

  openClientPortalView(report_id: number): void {
    this.clientPortalNewReportId.next(report_id);
    this._openClientPortalViewSubject.next(true);
    this.isClientPortalViewOpen = true;
  }

  close(): void {
    this._openSubject.next(false);
    this.isOpen = false;
  }

  closeClientPortalView(): void {
    this._openClientPortalViewSubject.next(false);
    this.isClientPortalViewOpen = false;
  }
}
