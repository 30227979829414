import { Injectable } from '@angular/core';
import { Observable, Subscriber, Subscription } from 'rxjs';
import { ApiService } from '../base/api.service';
import { ServerResponse } from '../../interfaces/base/server.response';
import { ApiEndpointsService } from '../base/api-endpoints.service';
import { ClientPortalGroup } from '../../interfaces/client-portal-groups/client-portal-group';
import { User } from '../../interfaces/routering/user';
import { Report } from '../../interfaces/report/report';

@Injectable()

export class ClientPortalGroupsService {

  constructor(private api: ApiService,
              private apiEndpoints: ApiEndpointsService,
  ) {
  }

  all(): Observable<ClientPortalGroup[] | boolean> {
    return new Observable<ClientPortalGroup[] | boolean>((o: Subscriber<ClientPortalGroup[] | boolean>) => {
      const subscription: Subscription = this.api.get(this.apiEndpoints.get('client-portal-groups.index'))
        .subscribe((response: ServerResponse): void => {
          if (typeof response.data !== 'undefined') {
            o.next(<ClientPortalGroup[]>response.data);
          } else {
            o.next(false);
          }
        }, (): void => {
          o.next(false);
        });

      return (): void => {
        subscription.unsubscribe();
      };
    });
  }

  get(id: number): Observable<ClientPortalGroup | boolean> {
    return new Observable<ClientPortalGroup | boolean>((o: Subscriber<ClientPortalGroup | boolean>) => {
      const subscription: Subscription = this.api.get(this.apiEndpoints.get('client-portal-groups.read', {':id': id}))
        .subscribe((response: ServerResponse): void => {
          if (typeof response.data !== 'undefined') {
            o.next(<ClientPortalGroup>response.data);
          } else {
            o.next(false);
          }
        }, (): void => {
          o.next(false);
        });

      return (): void => {
        subscription.unsubscribe();
      };
    });
  }

  create(formData: any): Observable<ServerResponse> {
    return this.api.post(this.apiEndpoints.get('client-portal-groups.create'), formData);
  }

  update(id: number, formData: any): Observable<ServerResponse> {
    return this.api.put(this.apiEndpoints.get('client-portal-groups.update', {':id': id}), formData);
  }

  users(id: number): Observable<User[] | boolean> {
    return new Observable<User[] | boolean>((o: Subscriber<User[] | boolean>) => {
      const subscription: Subscription = this.api.get(this.apiEndpoints.get('client-portal-groups.users', {':id': id}))
        .subscribe((response: ServerResponse): void => {
          if (typeof response.data !== 'undefined') {
            o.next(<User[]>response.data);
          } else {
            o.next(false);
          }
        }, (): void => {
          o.next(false);
        });

      return (): void => {
        subscription.unsubscribe();
      };
    });
  }

  reports(id: number): Observable<Report[] | boolean> {
    return new Observable<Report[] | boolean>((o: Subscriber<Report[] | boolean>) => {
      const subscription: Subscription = this.api.get(this.apiEndpoints.get('client-portal-groups.reports', {':id': id}))
        .subscribe((response: ServerResponse): void => {
          if (typeof response.data !== 'undefined') {
            o.next(<Report[]>response.data);
          } else {
            o.next(false);
          }
        }, (): void => {
          o.next(false);
        });

      return (): void => {
        subscription.unsubscribe();
      };
    });
  }

  resendInvitation(id: number): Observable<ServerResponse> {
    return this.api.get(this.apiEndpoints.get('client-portal-groups.resend-main-contact-invitation', {':id': id}));
  }

  removeMainContact2FACode(id: number): Observable<ServerResponse> {
    return this.api.get(this.apiEndpoints.get('client-portal-groups.remove-main-contact-2fa-code', {':id': id}));
  }

  delete(id: number): Observable<boolean> {
    return new Observable<boolean>((o: Subscriber<boolean>) => {
      const subscription: Subscription = this.api.delete(this.apiEndpoints.get('client-portal-groups.delete', {':id': id}))
        .subscribe((response: ServerResponse): void => {
            if (response) {
              o.next(true);
            } else {
              o.next(false);
            }
          },
          (): void => o.next(false));

      return (): void => {
        subscription.unsubscribe();
      };
    });
  }
}
